module.exports = {
	siteUrl: 'https://formworkfull.gatsbyjs.io',
	buildsUrl: 'https://formworkfull.gtsb.io',
	previewUrl: 'https://preview-formworkfull.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/47e275c8-b3f8-439f-b1f3-eb40d54b14d0',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}
